// src/ChatInput.js

import React from 'react';

const ChatInput = ({
                       prompt,
                       handleInputChange,
                       handleSubmit,
                       handleClearChat,
                       handleCopyChat,
                       handleSaveChat,
                       handleDownloadMemories,
                       handleNextChunk,
                       currentChunkIndex,
                       chunks,
                       fileContent,
                       fileName,
                       saveGeneratedFile,
                   }) => {
    return (
        <form onSubmit={handleSubmit}>
      <textarea
          value={prompt}
          onChange={handleInputChange}
          placeholder="Hi! How may I help you?"
          rows="4"
          style={{
              width: 'calc(100% - 20px)',
              resize: 'both',
              maxHeight: 'calc(20 * 1.5em)',
          }}
      />
            <div className="button-container">
                <button className={"justinbearcat-send"} tabIndex="0" type="submit" title="Send to JUSTIN">
                    💬 Send
                </button>
                <button className={"justbearcat-clear"} type="button" onClick={handleClearChat} title="Clear Chat">
                    ❌ Clear
                </button>

                {currentChunkIndex < chunks.length - 1 && (
                    <button type="button" onClick={handleNextChunk} title="Next Chunk">
                        Next Chunk
                    </button>
                )}
                {fileContent && (
                    <button className={"justinbearcat-download"}
                            type="button"
                        onClick={saveGeneratedFile}
                        title="Download Generated File"
                    >
                        Download {fileName}
                    </button>
                )}
            </div>
        </form>
    );
};

export default ChatInput;
