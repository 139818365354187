import React from 'react';

const NYXNoCodeGuide = () => {
    return (
        <div className="document">
            <h1>📘 NYX NoCode Guide</h1>
            <p>
                Welcome to NYX NoCode! This guide will help you turn your ideas into interactive digital projects without needing to write any code. Let’s dive into the basics of how NYX NoCode works and explore some of the creative tools at your fingertips.
            </p>
            <p>When you click Create Now from the Gallery, you enter the Create section of NYX NoCode, where you will find your Prompt Pad and Doodle Pad.</p>
            <h2>The Gallery</h2>
            <p>When you first sign in to NYX NoCode, you land in the Gallery. This is where all of your files are stored including those that you generate and those that you upload yourself. The Gallery automatically has two folders, including a Mena School District folder with school images you can use, and a doodles folder for any doodles you create.</p>
            <h2>The Prompt Pad</h2>

            <p>
                <strong>What is a Prompt?</strong><br/>
                A prompt is a description of what you want to create. For example, you might say, “Mountain bike.” NYX NoCode will take this prompt and transform it into a web page that explains mountain bikes, in seconds. Or you could say "35mm photograph of a mountain bike." Or even "A newspaper article about a brilliant student team that won the Mena NoCode hackathon with their idea for a dog sitting service for mountain bikers." Your prompt can be short or really long, just remember to Save Prompt if you are working on it, so you can find it again. You can include your own text, or let NYX NoCode write your text. You can include links to other creations, which gives you the ability to make multi-page websites.
            </p>
            <p>
                <strong>How to Write a Great Prompt</strong><br/>
                Writing a great prompt, or prompt engineering, involves describing what you want in a way that the AI will understand. Sometimes this means you have to break your idea down into multiple prompts. Think about what you want your project to look like, its purpose, and any specific features you’d like it to have. Keep it clear and descriptive. You can ask Justin Bearcat for help in creating prompts.
            </p>

            <p>
                <strong>The Generate Button</strong><br />
                The magic happens when you press Generate. That's when your prompt is handed to NYX NoCode's powerful AI and beautiful artwork and web pages are returned. Make sure to Save Web Page after you Generate.
            </p>

            <h2>The Doodle Pad</h2>
            <p>
                <strong>What’s the Doodle Pad?</strong><br/>
                The Doodle Pad lets you draw ideas visually, and then those doodles are analyzed in real time and a prompt is generated in the box. You can experiment with colors, line styles, and special effects to create eye-catching designs. If you hit the Generate button right after you doodle, you will get a web page of your doodles. If you delete everything in the prompt box except the final description of your doodle, that will generate an image of what you drew.
            </p>
            <ul>
                <li><strong>Color Options:</strong> Choose from preset colors or pick any hue using the square color picker.</li>
                <li><strong>Line Customization:</strong> Adjust line width and opacity to bring your design to life.</li>
                <li><strong>Special Modes:</strong> Try Rainbow Mode, Mirror Draw, Kaleidoscope, and more for unique effects.</li>
                <li><strong>Shape Stamp:</strong> Add shapes like circles and squares to structure your designs. You need to select Shape Stamp again to make additional shapes.</li>
            </ul>

            <h2>The Help Box</h2>
            <strong>What's in the Help Box?</strong>
            <p>In the Help Box you will find both links to helpful resources and Justin Bearcat, and AI designed specifically to help you with the Mena NoCode Hackathon. Justin Bearcat can help you with planning, creating prompts, and in how to use NYX NoCode.</p>

            <h2>Troubleshooting and Tips</h2>
            <p>
                <strong>Stuck on an Idea?</strong> Start with a simple concept, and expand from there. You can always edit your project as new ideas come up.
            </p>
            <p>
                <strong>Is that really a word? What's up with the spelling errors?</strong> Usually you will find this only in images. The AI image generators are using tokenized, or shortened, versions of words and phrases, and sometimes they just produce total nonsense. But sometimes the image generator gets a word right. When you need words, it is best to create text to go along with your images.
            </p>
            <p>
                <strong>Do I need to fact check AI's work? </strong>Yes! It is always best to verify the output of AI using another source if you have any questions at all about whether or not it is right. Large Language Models, or LLMs, which drive systems like this are always guessing what you are going to say next. If they don't have enough facts for a good guess, they can make things up! This is called hallucination, and you should always be aware that generative AI systems like LLMs can frequently hallucinate. There are settings that AI engineers can adjust that help to reduce hallucinations but they can also reduce creativity.
            </p>
            <p>
                <strong>I'm stuck! How can I get down to the Save Page button?</strong> If buttons scroll off the page, you can try making the prompt text input area smaller by dragging the lower right hand corner up. Or you can click in the text input area and then press the tab key to move from button to button.
            </p>
            <p>
                <strong>I made a doodle. Now how do I get it to stop showing and just generate the drawing?</strong> Just delete the link that goes to the doodle from the prompt, so you have something left like "colorful rain drops," then hit Send. Alternatively, if you don't do anything after you make a doodle and hit send, all the steps to create the doodle will show up on the web page."
            </p>

            <p>
                <strong>How do I get my doodle description to go back into my prompt?</strong> Just Save Doodle again and the image will be analyzed and the prompt will go back into the input box."
            </p>
            <p>
                <strong>Can I save prompts and reuse them later? Where do they go?</strong> Yes, you can save your prompts by clicking the Save Prompt button BEFORE you hit Send. You can recall, edit, and save them again. Also, the prompts are permanently stored in your Gallery as text files, so you can find them again there as well."
            </p>
            <p>
                <strong>Can I use NYX NoCode to build non-AI systems? </strong>YES! Imagine a world where you build your tools as you need them, like in Minecraft. That is what NYX NoCode can do for you. If you need a word processor, ask NYX to create one! If you need a calculator with nice big buttons and a theme that you like, ask NYX to make an interactive order form. We don't know what all NYX NoCode will create yet and we are hoping you push the boundaries.
            </p>
            <p>
                <strong>Can I use NYX NoCode to build AI systems? </strong>Well, yes and no. Using an AI system with the applications you build will require a more complex setup than NYX NoCode alone. You could build the application portion and then link to what is called an API, or application programming interface, for your AI, but that is outside the scope of this hackathon.
            </p>
            <p>
                <strong>What programming languages does NYX NoCode use? </strong>Primarily, NYX NoCode develops web applications, which involve HTML. NYX NoCode's stack is HTML5 and javascript with embedded styles. HTML, or Hypertext Markup Language, is how a web browser knows what to do with the information that comes into it. CSS3, or Cascading Style Sheets, are often used to provide the design like font size, alignment, backgrounds and much more. In NYX NoCode the styles are included inside the HTML file, but they could be moved to a css file. NYX NoCode uses Python for jobs like plotting graphs and it can use Flask to set up web pages if specified. We don't know what all NYX NoCode will do just yet.
            </p>
            <p>
                <strong>What natural languages do NYX NoCode and Justin Bearcat speak? </strong> Quite a few! In addition to English, we have tried Spanish, French, Italian, Hindi, and Russian. There are many more, just ask the AI to do it.
            </p>
            <p>
                <strong>Can I use my own files?</strong> Yes. Just drag and drop them into the Gallery's File Upload box, or insert any web link as part of your prompt to link to that page.
            </p>
            <p>
                <strong>Is there a best way to talk to an LLM?</strong> Yes. Be respectful to the AI and talk with it in the same way that you would like to be spoken to. While this is not a real person, it has been trained on the knowledge of real people. If you are nice and polite when you interact with NYX NoCode and Justin Bearcat, you will get the best results (please and thank you go a long way).
            </p>
            <p>
                <strong>Can I make interactive and animated content, like a simulation?</strong> Yes. Simply use words like those in your description. Instead of asking for a gradient background, ask for an animated gradient background. Instead of just asking for an animated bouncing ball, ask for an interactive animated bouncing ball where you click to start and click again to stop. The possiblities are just about endless. You can even invent new ways for content to appear, like a slide show where the images spin and flash.
            </p>
            <p>
                <strong>Can I make complex pages and applications?</strong> Yes. The best way to start is a little bit a time. Remember, you can make less complex items and then link them using NYX NoCode (just supply the links to the pages you make in a new prompt).
            </p>
            <p>
                <strong>Is this private?</strong> Yes. We do not collect any personal information at all on student hackathon participants. The AI chat conversations are done anonymously and are not saved in the system. Microsoft can see the system and will censor inappropriate requests.
            </p>
            <p>
                <strong>Why is this called a hackathon?</strong> While 'hacker' is often used as a negative term used to refer to a bad actor that breaks into systems, in this case it is used in its original form, which means someone who is testing the boundaries of a system to make new discoveries.
            </p>

            <p>
                <strong>NYX Support:</strong> Reach out to your teacher or ask Justin Bearcat for help if you get stuck!
            </p>
            <p>Here are some video lessons and examples to help you. They each pop out in a separate window.</p>
            <h2>Video Lessons</h2>
            <ul>
                <li><a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy@uark.edu/Prompt_Page_Content_DOCTYPE_html_html_langen_head_meta_charset-20241031-060345.html"} rel="noreferrer" target={"_blank"}>Sign In, Sign Out</a>
                </li>
                <li><a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy@uark.edu/httpsnyxblobblobcorewindowsnetnyxnocodekilroy40uarkedu2F20241031_0-20241031-052536.html"} rel="noreferrer" target={"_blank"}>Gallery, Files and Prompts</a>
                </li>
                <li><a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy%40uark.edu%2FNYX_NoCode_for_Mena_School_District_NoCode_Hackathon_2024_How_to_make_an_image_-20241031-063024.html"} rel="noreferrer" target={"_blank"}>Doodle Pad</a>

                </li>
            </ul>
            <h2>Examples</h2>
            <ul><li><a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Prompt_add_below_my_first_prompt_To_get_the_other_features_I_kept_clicking_th-20241105-061759.html"} rel="noreferrer" target={"_blank"}>Bouncing, Moving 3d Spheres</a>–This page includes a video demo (no sound), a link to the demo, a link to the final text prompt, and a link to the original text prompt and original demo.
            </li>
            <li><a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/animated_background_with_bright_colors_that_says_welcome_to_the_party-20241104-175015.html"} rel="noreferrer" target={"_blank"}>Welcome to the Party - backlighted animated image</a>–Prompt: "Animated background colors with a sign that says Welcome to the Party!"</li>
                <li>
                    <a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy@uark.edu/interactive_simulation_of_a_rain_storm-20241103-015816.html"} rel="noreferrer" target={"_blank"}>Rain Simulator</a> Prompt: Interactive simulation of a rainstorm–</li>
            <li>
                <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/interactive_3d_globe_that_I_can_spin_please-20241105-221515.html"}  rel="noreferrer" target={"_blank"}>Earth-style animation.</a>–Prompt: Interactive 3d globe that I can spin.
            </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/3d_rendered_ball_that_bounces_around_the_screen_with_a_light_that_shines_on_it_-20241105-213416.html"} rel="noreferrer" target={"_blank"}>Spotlight</a>–Prompt: 3d Rendered Ball that Bounces Around the Screen.
                </li>
<li>
    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/animated_moving_search_lights_across_a_mountain_town_with_a_mountain_bike_in_the-20241105-213617.html"}  rel="noreferrer" target={"_blank"}>Spotlights over the Trails of Mena</a>–Prompt: Animated seach lights moving across a mountain town with a mountain bike in the foreground.
</li>
                <li>
                    <a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy@uark.edu/make_a_flask_page_showcasing_my_dog_sitting_service_for_mountain_bikers-20241103-035544.html"}  rel="noreferrer" target={"_blank"}>Dog Sitting Service</a>–Prompt: Make a flask page showcasing my dogsitting service for mountain bikers.
                </li>
                <li>
                    <a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy@uark.edu/httpsnyxblobblobcorewindowsnetnyxnocodekilroyuarkedu1730630044995do-20241103-043507.html"}  rel="noreferrer" target={"_blank"}>Colorful string of lights</a>–This colorful string of lights was created with the Doodle Pad, which generated a prompt and the background image.
                </li>
                <li>
                    <a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy@uark.edu/the_best_new_thing_youve_seen_all_day-20241103-084451.html"} rel="noreferrer" target={"_blank"} >Our Hackathon Students are Inspirational</a>–Prompt: The best new thing you've seen all day
                </li>
                 <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Mena_Mountain_Bike_Sticker_Store_Please_fill_in_details_for_the_store_simulation-20241105-070144.html"} rel="noreferrer" target={"_blank"}>Mena Mountain Bike Sticker Store</a>–Each sticker was made with a separate prompt. It took a few tries to for Mena to be spelled correctly. Prompt: Build a Mena Sticker Store simulation. Plus I had selected each pre-built sticker from the gallery, and clicked the icons so the urls go into the prompt.
                </li>
                <li>
                    <a href={"https://nyxblob.blob.core.windows.net/nyxnocode/kilroy%40uark.edu%2Fa_form_to_help_me_plan_my_trip_to_Mena_Arkansas_that_lets_me_select_places_to_v-20241031-033509.html"} rel="noreferrer" target={"_blank"}>Mena Trip Planner</a>–Prompt: a form to help me plan a trip to Mena, Arkansas that lets me select places to visit, add the number of days and my name, and print the list.
                </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/httpsdrivenyxbabynyxnocodeTeam001menanyxnocodecomdoodles173082003664-20241105-092305.html"} rel="noreferrer" target={"_blank"}>Image Recognition of a Hamster Drawing</a>–This shows the trail that is automatically created as a sketch is done on the Doodle Pad. The caption is what image recognition AI guesses the picture is each step of the way. These are really easy to make, and what happens when you click Generate after you draw something and don't clear the prompt box.
                </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Create_an_interactive_app_with_a_clickable_spinning_wheel_that_blends_colors_as_-20241113-170734.html"} rel="noreferrer" target={"_blank"}>Interactive Spinning Color Wheel</a>–Prompt: Create an interactive app with a clickable spinning wheel that blends colors as it spins. The wheel should have multiple segments, each with a different color. When clicked, the wheel spins smoothly and creates a whirl of blended colors that's visually appealing. Each time I click it, make it go faster and faster. When I click the Reverse Button, reverse it. When I click stop, stop it.
                </li>

            <li>
                <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/flash_these_fast_like_an_animation_httpsdrivenyxbabynyxnocodeTeam00140m-20241116-205651.html"} rel="noreferrer" target={"_blank"}>Animate Your Doodles</a>–Prompt: (after selecting the doodles from the Gallery and then clicking them in the Prompt Box) Flash these fast, like an animation.
            </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/what_if_outer_space_aliens_rode_bikes_in_Mena-20241116-045005.html"} rel="noreferrer" target={"_blank"}>What If?</a>–Prompt: What If Outer Space Aliens Rode Bikes in Mena? (Here I made up a tall tale asking a silly question.)
                </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Trails_at_Mena_water_bottle-20241116-052419.html"} rel="noreferrer" target={"_blank"}>Trails at Mena Water Bottle</a>–Prompt: Trails at Mena Water Bottle. Here I made up a pretend product that might be sold in the region.
                </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/add_this_mp3_as_the_music_xo_httpsdrivenyxbabynyxnocodeTeam00140menanyx-20241118-090157.html"} rel="noreferrer" target={"_blank"}>Happy Holidays Slide Show with Music</a>–This took me a few rounds. First I made the images. Then I selected the images from the Gallery. Then I went into the Prompt Lab and selected all the images. I asked NYX Nocode to make a slide show with spinning images, and a music player that you can start and stop. The music is downloaded from the Library of Congress' website and uploaded to NYX NoCode..
                </li>
                <li>
                    <a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Red_Bearcat_Beanie_Bag_Character_dressed_as_an_Astronaut-20241126-040816.html"} rel="noreferrer" target={"_blank"}>Make Your Own Characters</a>–Prompt: Red Bearcat Beanie Bag Character

                    Dressed as an Astronaut
                </li>

            </ul>


            </div>
    );
};


export default NYXNoCodeGuide;
