import React, { useState } from 'react';
import hero from './help_justin.webp';
import './App.css';
import './Chatbot.css';
import Chatbot from './Chatbot';
import NYXNoCodeGuide from "./NYXNoCodeGuide";
import MissionTrailsOverview from "./MissionTrailsOverview";
import SponsorsMentors from "./SponsorsMentors";
import KuafInterview from "./KUAF_NPR_Trails_at_Mena_Karen_Kilroy_Interview";

function AppContent() {
    const [activeDoc, setActiveDoc] = useState(null); // Track the active document

    const renderActiveDocument = () => {
        switch (activeDoc) {
            case 'KuafInterview':
                return <KuafInterview />;
              case 'NYXNoCodeGuide':
                return <NYXNoCodeGuide />;
            case 'MissionTrailsOverview':
                return <MissionTrailsOverview />;
            case 'SponsorsMentors':
                return <SponsorsMentors />;
            default:
                return null;
        }
    };

    return (
        <div className="app-content">
            <header className="App-header">
                <img src={hero} className="hero-img" alt="Justin" />

                {/* Display list of links */}
                <div className="resource-links">
                    <h2>Resources</h2>
                    <div>
                        <button onClick={() => setActiveDoc('KuafInterview')}>KUAF Report: Mena NoCode Hackathon</button>
                    </div>

                    <div>
                        <button onClick={() => setActiveDoc('NYXNoCodeGuide')}>NYX NoCode Guide</button>
                    </div>
                    <div>
                        <button onClick={() => setActiveDoc('MissionTrailsOverview')}>Mission: Trails at Mena Overview</button>
                    </div>
                    <div>
                        <button onClick={() => setActiveDoc('SponsorsMentors')}>Sponsors & Mentors</button>
                    </div>
                </div>

                {/* Render the selected document */}
                <div className="documentation-content">
                    {renderActiveDocument()}
                </div>

                <div className="auth-info">
                    <p>Signed in as {process.env.NODE_ENV === 'development' ? "karen@knowbots.org" : "Guest"}</p>
                </div>
                <div className="chat-container">
                    <div className="chat-popup">
                        <div className="response-container">
                            <Chatbot setFilterCriteria={(criteria) => console.log(criteria)} />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default AppContent;
