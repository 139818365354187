import React, { useEffect, useRef } from 'react';

const SpeechControls = ({
                             isSpeaking,  // Accept isSpeaking as a prop
                            isLoading,  // Accept isLoading as a prop
                        }) => {

    // Ref to the button element
    const listenButtonRef = useRef(null);

    // Set focus to the Listen button when the component mounts
    useEffect(() => {
        if (listenButtonRef.current) {
            listenButtonRef.current.focus();
        }
    }, []);


    return (
        <div className="speech-controls-container">
            <div className="speech-controls">
                {isLoading && (
                    <div className="button-overlay">
                        <div className="overlay-text">Generating Response...</div>
                    </div>
                )}
            </div>
            {isSpeaking && (
                <div className="button-overlay">
                    <div className="overlay-text">Generating_Response</div>
                </div>
            )}
        </div>
    );
};

export default SpeechControls;
