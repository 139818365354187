import React from 'react';
import './KuafInterview.css'; // External CSS for better styling management

const KuafInterview = () => {
    const audioSrc = "https://cpa.ds.npr.org/s197/audio/2024/11/20241126-nyxnocode.mp3";

    return (
        <div className="document">
            <header>
                <h1>KUAF News Report 🎙️</h1>
            </header>

            <p>

                   Nyx No-Code Enables Mena Students to Create Using AI 🚀🤖
                by Jack Travis
            </p>
            <p>Published November 26, 2024, at 12:01 PM CST</p>

            {/* Audio Player Section */}
            <div className="audio-player">
                <audio controls>
                    <source src={audioSrc} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </div>

            <p>
                Students in the Mena School District are participating in a unique challenge this November. During the No-Code Hackathon, students use the AI-driven platform Nyx No-Code to create promotional materials for the town’s new mountain bike trail system, Trails at Mena, without typing any code. Karen Kilroy is a software engineer and founded Nyx No-Code. She recently spoke to Ozarks at Large’s Jack Travis.
            </p>
            <p>
                <strong>Tags:</strong> Ozarks at Large 🎧, Artificial Intelligence 🤖
            </p>
        </div>
    );
};

export default KuafInterview;
