import React from 'react';

const MissionTrailsOverview = () => {
    return (
        <div className="document">
            <h1>🚴 NoCode Hackathon Mission</h1>

            <p><a href={"https://drive.nyx.baby/nyxnocode/Team001@mena.nyxnocode.com/Press_Release_Mena_NoCode_Hackathon_2024_Page_Content_DOCTYPE_html_html_la-20241108-115549.html"}>Read the Official Press Release</a></p>
            <p>
                Your mission is to use NYX NoCode to create content that will promote Mena as a mountain biking destination.
            </p>

            <h2>🚴 Trails at Mena Overview</h2>
            <p>
                The Mena School District NoCode Hackathon is all about combining technology with the great outdoors. Here’s why your project matters and how it will contribute to the Trails at Mena Project.
            </p>

            <h3>The Trails at Mena Project</h3>
            <p>
                <strong>What is it?</strong><br />
                The Trails at Mena Project is a new initiative to make Mena, Arkansas, a top destination for mountain biking and outdoor adventure. This network of singletrack trails is designed to attract bikers and nature lovers from all over.
            </p>
            <p>
                <strong>Project Goals</strong>
                <ul>
                    <li>Promote outdoor recreation and active lifestyles in Mena.</li>
                    <li>Support local tourism and business growth.</li>
                    <li>Create a space for the community to connect with nature and enjoy the outdoors.</li>
                    <li>Encourage environmental stewardship and sustainable practices.</li>
                </ul>
            </p>

            <h2>Your Role in Promoting the Trails</h2>
            <p>
                <strong>How Your Project Helps</strong><br />
                By using NYX NoCode to create apps, websites, and interactive features, you’re helping spread the word about the Trails at Mena Project.
            </p>
            <p>
                <strong>Project Ideas</strong>
                <ul>
                    <li>Virtual Trail Buddy: An app that suggests trail routes and challenges.</li>
                    <li>Trail Story Mode: An interactive adventure on the trails with mini-challenges.</li>
                    <li>Mythical Creature Tracker: A scavenger hunt where bikers look for “creatures” along the trail.</li>
                </ul>
            </p>

        </div>
    );
};

export default MissionTrailsOverview;
